import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

const THANKS = () => (
  <Layout>
    <h1>送信が完了しました！</h1>

<p><br />お問い合わせありがとうございます。</p>
<p>
	返信が必要な要件の場合、通常1週間以内に返信いたします。<br />
	しばらく待っても返信がない場合、もう一度送信いただくか、<br />
	Twitter の DM 等から連絡いただけると幸いです。<br />
	<br />
	ご意見・ご感想も大切に読ませていただきます。
	</p>
	<p>引き続きよろしくお願いいたします！</p>

    <Link to="/">トップへ戻る</Link>
  </Layout>
)

export default THANKS
